import React, { useContext, useState } from 'react';
import ChargingInfoImage from '../../assets/images/Infographic_charging.svg';
import DischargingInfoImage from '../../assets/images/Infographic_discharging.svg';
import config from '../../env/environment.js';
import { useLocation, useNavigate } from 'react-router-dom';
import ERROR_STATUS from '../../constants/ErrorStatusConst';
import { Stack, Tabs, Tab, Button, Modal } from 'react-bootstrap';
import moment from 'moment';
import './OnBoardReady.scss';
import LoadingScreen from '../LoadingScreen/LoadingScreen';
import { customerStateAtom } from '../../atoms/customerState.js';
import { useRecoilState, useRecoilValue } from 'recoil';
import BackButton from '../../assets/icons/Back_Arrow_Left_icon.svg';
import {
    onboardingPageCurrentlyShownAtom,
    onboardingPreviousPageAtom,
    onboardingScheduleAtom
} from '../../atoms/vehicleState.js';
import { useBackButtonHandler } from '../OnBoardingContainer/UseBackButtonHandler.jsx';
import OnBoardingPagesConst from '../../constants/OnBoardingPagesConst.js';
import { AMPLITUDE_EVENTS, trackEvent } from '../../util/Amplitude.js';
import { RefreshCustomerStatusContext } from '../../pages/HomePage/RefreshCustomerStatusContext.jsx';

export default function OnBoardReady({ isEditFlow }) {
    const navigate = useNavigate();
    const VIN = sessionStorage.getItem('VIN');
    const [loading, setLoading] = useState(false);
    const customerState = useRecoilValue(customerStateAtom);

    const [showEditDialog, setShowEditDialog] = useState(false);
    const [onboardingPreviousPage, setOnboardingPreviousPage] = useRecoilState(
        onboardingPreviousPageAtom
    );
    const [, setOnboardingPageCurrentlyShown] = useRecoilState(onboardingPageCurrentlyShownAtom);
    const [schedule] = useRecoilState(onboardingScheduleAtom);
    const refreshStatusContext = useContext(RefreshCustomerStatusContext);

    const location = useLocation();

    useBackButtonHandler(location, goBackToPreviousPage);

    const toggleEditDialog = () => setShowEditDialog(!showEditDialog);

    const callInverter = async () => {
        setLoading(true);
        await fetch(config.VEHICLE_STATUS_API_URL + `/energy-app/v1/inverter/${VIN}`, {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Auth-Token': sessionStorage.getItem('catToken'),
                'Content-Type': 'application/json'
            },
            body: createRequestBody(customerState, schedule)
        })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error(ERROR_STATUS.Unexpected);
                }
            })
            .then((data) => {
                if (data.status === 'SUCCESS') {
                    refreshStatusContext.refreshCustomerStatus();
                } else {
                    throw new Error('Inverter call failed');
                }
            })
            .catch((error) => {
                trackEvent(AMPLITUDE_EVENTS.IP_ERROR);
                navigate('/error', { state: ERROR_STATUS.Unexpected });
            })
            .finally(() => {
                setLoading(false);
            });
    };

    function createRequestBody(customerState, schedule) {
        const dischargeSchedules = [
            ...schedule.weekdays.dischargeList.map((window) => ({
                dayType: 'WEEKDAY',
                startTime: window.startTime,
                endTime: window.endTime
            })),
            ...(schedule.weekends && schedule.weekends.dischargeList
                ? schedule.weekends.dischargeList.map((window) => ({
                      dayType: 'WEEKEND',
                      startTime: window.startTime,
                      endTime: window.endTime
                  }))
                : [])
        ];

        return JSON.stringify({
            locationId: customerState.location.locationId,
            latitude: customerState.location.latitude,
            longitude: customerState.location.longitude,
            timeZoneOffset: customerState.location.timeZoneOffset,
            zipCode: customerState.location.zipCode,
            utilityId: schedule.utilityId,
            planId: schedule.planId,
            dischargeSchedules: dischargeSchedules,
            isEditSchedule: isEditFlow
        });
    }

    const formatTimestamp = (data) => {
        if (data.length !== 0) {
            return data.map((window, index) => {
                const { startTime, endTime } = window;
                const start = moment(startTime, 'HH:mm');
                const end = moment(endTime, 'HH:mm');
                const startTimeStamp =
                    start.minutes() === 0 ? start.format('h A') : start.format('h:mm A');
                const endTimeStamp = end.minutes() === 0 ? end.format('h A') : end.format('h:mm A');

                return (
                    <span key={index} data-testid="formatted-timestamp">
                        {startTimeStamp} - {endTimeStamp}
                    </span>
                );
            });
        }
    };

    function createChargeList(dischargeLIst) {
        return [
            {
                startTime: dischargeLIst[0].endTime,
                endTime: dischargeLIst[0].startTime
            }
        ];
    }

    const renderImage = (data) => {
        if (data) {
            return (
                <>
                    {data.dischargeList.length !== 0 && (
                        <>
                            <div className="image-container">
                                <img src={ChargingInfoImage} alt="Charging Schedule Graphics" />
                                <div className="text">
                                    {formatTimestamp(createChargeList(data.dischargeList))}
                                </div>
                            </div>
                            <div className="image-container">
                                <img
                                    src={DischargingInfoImage}
                                    alt="Discharging Schedule Graphics"
                                />
                                <div className="text">{formatTimestamp(data.dischargeList)}</div>
                            </div>
                        </>
                    )}
                </>
            );
        }
    };

    function goBackToPreviousPage() {
        trackEvent(AMPLITUDE_EVENTS.IP_ONBOARD_READY_BACK);
        if (onboardingPreviousPage === OnBoardingPagesConst.UTILITY_PLAN) {
            setOnboardingPreviousPage(OnBoardingPagesConst.ONBOARD_READY);
            setOnboardingPageCurrentlyShown(OnBoardingPagesConst.UTILITY_PLAN);
        } else if (onboardingPreviousPage === OnBoardingPagesConst.EDIT_SCHEDULE) {
            setOnboardingPreviousPage(OnBoardingPagesConst.ONBOARD_READY);
            setOnboardingPageCurrentlyShown(OnBoardingPagesConst.EDIT_SCHEDULE);
        }
    }

    const handleTabSelect = (key) => {
        trackEvent(AMPLITUDE_EVENTS.IP_ONBOARD_READY_TAB_SWITCH, {
            Timestamp: new Date().toISOString(),
            tab: key
        });
    };

    if (loading) {
        return <LoadingScreen text="Sending Request " />;
    } else {
        return (
            <div className="onboard-ready">
                <div className="scroll-content p-3">
                    <button
                        className="arrow-button back"
                        onClick={() => {
                            goBackToPreviousPage();
                        }}
                    >
                        <img src={BackButton} alt="Back Button" />
                    </button>
                    <p className="title mb-1">
                        {isEditFlow ? 'Your Energy Schedule' : 'Welcome to Intelligent Power'}
                    </p>
                    <p className="status-text">
                        When plugged in, your F-150 Lightning will automatically generate a
                        personalized daily Intelligent Power schedule within these windows.
                    </p>
                    <div>
                        <Tabs
                            defaultActiveKey="Weekday"
                            id="fill-tab"
                            className="info-tabs mb-3"
                            fill
                            onSelect={handleTabSelect}
                        >
                            <Tab eventKey="Weekday" title="Weekday">
                                <div className="mt-3">
                                    <Stack className="info-graphics justify-content-center" gap={3}>
                                        {renderImage(schedule?.weekdays)}
                                    </Stack>
                                </div>
                            </Tab>
                            <Tab
                                eventKey="Weekend"
                                title="Weekend"
                                className="justify-content-center"
                            >
                                <div className="mt-3">
                                    <Stack className="info-graphics justify-content-center" gap={3}>
                                        {renderImage(schedule?.weekends)}
                                    </Stack>
                                </div>
                            </Tab>
                        </Tabs>
                        {isEditFlow && (
                            <p className="status-text">Changes may take 24 hours to reflect.</p>
                        )}
                    </div>
                </div>
                <div className="onboard-ready sticky-footer justify-content-center p-3">
                    <Stack>
                        <Button
                            className="continue-button mx-auto h-100 w-50 mb-2"
                            onClick={() => {
                                trackEvent(AMPLITUDE_EVENTS.IP_ONBOARD_READY_CONFIRM);
                                callInverter();
                            }}
                        >
                            Confirm
                        </Button>
                        <div className="footer-contact mx-auto w-100">
                            Need Help? <a href="mailto:myenergy@ford.com">Contact Us.</a>
                        </div>
                        <Modal
                            className="onboard-ready-modal"
                            show={showEditDialog}
                            animation={true}
                            centered
                        >
                            <Modal.Header closeButton onHide={toggleEditDialog}>
                                <span>Schedule doesn’t look right?</span>
                            </Modal.Header>
                            <Modal.Body>
                                <p>
                                    We’ve based your Intelligent Power schedule on the utility rate
                                    plan you’ve linked in FordPass.
                                </p>
                                <p>
                                    If this looks incorrect, try updating your Preferred Charge
                                    Times in FordPass by selecting “Recommended Times” and updating
                                    your utility rate plan
                                </p>
                                <div className="contact-link relative text-center">
                                    <span>
                                        Need help?
                                        <a href="mailto:myenergy@ford.com">Contact Us.</a>
                                    </span>
                                </div>
                            </Modal.Body>
                        </Modal>
                    </Stack>
                </div>
            </div>
        );
    }
}
