import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import OnBoardingContainer from './OnBoardingContainer.jsx';
import './EditScheduleModal.scss';
import UserType from '../../constants/UserTypeConst.js';
import { AMPLITUDE_EVENTS, trackEvent } from '../../util/Amplitude.js';
import EditIcon from '../../assets/icons/Edit_icon.svg';

export default function EditScheduleModal({ userType }) {
    const [showEditSchedule, setShowEditSchedule] = useState(false);

    return (
        <div>
            {userType === UserType.IP && (
                <button
                    className="edit-button"
                    onClick={() => {
                        trackEvent(AMPLITUDE_EVENTS.IP_EDIT_SCHEDULE_CLICKED);
                        setShowEditSchedule(true);
                    }}
                >
                    <img src={EditIcon} alt="Edit Schedule" />
                </button>
            )}
            <Modal
                show={showEditSchedule}
                animation={true}
                centered
                className="edit-schedule-modal"
            >
                <Modal.Body>
                    <div>
                        <OnBoardingContainer
                            isEditFlow={true}
                            exitOnboarding={() => setShowEditSchedule(false)}
                            data-testid="onboardingcontainer-component"
                        />
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
}
