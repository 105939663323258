import React, { useState } from 'react';
import { AMPLITUDE_EVENTS, trackEvent } from '../../util/Amplitude.js';
import config from '../../env/environment.js';
import { Alert, Button, Modal, Spinner } from 'react-bootstrap';
import './ChargeModal.scss';
import UserTypeConst from '../../constants/UserTypeConst.js';

export default function ChargeModal({
    buttonLabel,
    buttonDisabled,
    scheduleCalculated,
    setScheduleCalculated,
    userType
}) {
    const [showDialog, setShowDialog] = useState(false);
    const [showToast, setShowToast] = useState(false);
    const [showSpinner, setShowSpinner] = useState(true);
    const [toastText, setToastText] = useState(false);
    const VIN = sessionStorage.getItem('VIN');

    const POPUP_TEXT_CHARGE = {
        PROCESSING: 'Your charge request is processing',
        SUCCESS: 'Your charge request was successful',
        FAILED: 'Sorry, we weren’t able to process your charge request'
    };

    const isCharging = buttonLabel === 'Stop Charging';
    const isGridUser = userType === UserTypeConst.GRID || userType === UserTypeConst.GRID_BPT;
    const isBptUser = userType === UserTypeConst.BPT;

    function trackChargeEvent(isCharging) {
        let event = isCharging ? AMPLITUDE_EVENTS.STOP_CHARGING : AMPLITUDE_EVENTS.START_CHARGING;
        trackEvent(event);
    }

    const stopOrStartCharging = async () => {
        let COMMAND = isCharging ? 'PAUSE' : 'START';
        trackChargeEvent(isCharging);
        setToastText(POPUP_TEXT_CHARGE.PROCESSING);
        setShowToast(true);
        await fetch(config.VEHICLE_STATUS_API_URL + `/energy-app/v1/${COMMAND}/charge/${VIN}`, {
            method: 'POST',
            mode: 'cors',
            headers: { 'Auth-Token': sessionStorage.getItem('catToken') }
        })
            .then((response) => {
                if (response.status === 200) {
                    return response.json();
                }
                console.log('response status', response);
                throw new Error(`${COMMAND} Charge Request Failed`);
            })
            .then((data) => {
                if (data.status === 'SUCCESS') {
                    setShowSpinner(false);
                    setScheduleCalculated(false);
                    setToastText(POPUP_TEXT_CHARGE.SUCCESS);
                    setTimeout(() => {
                        setShowToast(false);
                    }, 6000);
                }
            })
            .catch((error) => {
                console.error(error.message);
                setShowSpinner(false);
                setToastText(POPUP_TEXT_CHARGE.FAILED);
                setTimeout(() => {
                    setShowToast(false);
                }, 10000);
            });
    };

    return (
        <div className="charge-modal-container">
            <Button
                className={`action-button ${isCharging ? 'stop' : 'start'} ${isGridUser ? 'grid' : ''} ${isBptUser ? 'bpt' : ''}`}
                variant="secondary"
                disabled={buttonDisabled || !scheduleCalculated}
                onClick={() => setShowDialog(true)}
            >
                {buttonLabel}
            </Button>
            <Modal show={showDialog} animation={true} centered>
                <Modal.Body>
                    <p>Are you sure you want to proceed?</p>
                    <div className="d-flex justify-content-around my-3">
                        <Button
                            className="option-button"
                            variant="light"
                            onClick={() => {
                                setShowDialog(false);
                                stopOrStartCharging();
                            }}
                        >
                            Yes
                        </Button>
                        <Button
                            className="option-button"
                            variant="light"
                            onClick={() => setShowDialog(false)}
                        >
                            No
                        </Button>
                    </div>
                </Modal.Body>
            </Modal>
            <Alert className="alert-bottom" show={showToast}>
                <div className="d-flex justify-content-center">
                    {showSpinner && (
                        <Spinner className="my-auto mr-2" animation="border" size="sm" />
                    )}
                    <span className="">{toastText}</span>
                </div>
            </Alert>
        </div>
    );
}
