import { atom } from 'recoil';

export const customerStateAtom = atom({
    key: 'customer',
    default: JSON.parse(sessionStorage.getItem('user'))
});

export const loginStartTimeAtom = atom({
    key: 'loginStartTime',
    default: 0
});
