import React from 'react';
import './OnBoardingPage.scss';
import { Stack, Button } from 'react-bootstrap';
import EmailIcon from '../../assets/icons/Email_icon.svg';
import TipIcon from '../../assets/icons/Tip_icon.svg';
import NotAuthorizedImage from '../../assets/icons/Not_authorized.svg';
import WarningIcon from '../../assets/icons/Generic_Warning_icon.svg';
import Logout from '../../util/Logout';
import { AMPLITUDE_EVENTS, trackEvent } from '../../util/Amplitude';
import PrivacyLinks from '../../components/PrivacyLinks/PrivacyLinks.jsx';
import OnBoardingContainer from '../../components/OnBoardingContainer/OnBoardingContainer.jsx';

export default function OnBoardingPage({ onboardStatus }) {
    if (onboardStatus === 'ONBOARD READY') {
        trackEvent(AMPLITUDE_EVENTS.IP_ONBOARD_READY);
        return (
            <OnBoardingContainer
                isEditFlow={false}
                exitOnboarding={() => {}}
                data-testid="onboardingcontainer-component"
            />
        );
    } else if (onboardStatus === 'PENDING') {
        return (
            <Stack gap={3} className="onboarding-content bg-white m-auto pending">
                <p className="title">Waiting for Verification</p>
                <img src={EmailIcon} className="icon-image mx-auto mt-10" alt="Email Icon" />
                <p className="status-text">
                    We’ll send you an email when your Ford Intelligent Power system is ready to use.
                </p>
                <p className="status-text">This may take up to 3 days.</p>
                <div className="flex px-7 mt-20">
                    <img src={TipIcon} className="mr-[-10px] mt-[-15px] mb-auto" alt="Tip Icon" />
                    <p className="tip-text">
                        Tip: Add this site to the home screen of your mobile device. <br></br>
                        <a
                            href="https://support.google.com/chrome/answer/9658361?hl=en&co=GENIE.Platform%3DiOS&oco=2"
                            target="_blank"
                            rel="noreferrer noopener"
                        >
                            Show me how &rarr;
                        </a>
                    </p>
                </div>
                <div className="contact-link">
                    Having trouble? <a href="mailto:myenergy@ford.com">Contact Us.</a>
                </div>
                <PrivacyLinks data-test-id="privacy-links" />
            </Stack>
        );
    } else if (onboardStatus === 'NOT READY') {
        return;
    } else if (onboardStatus === 'NO ACCESS') {
        trackEvent(AMPLITUDE_EVENTS.LOGIN_FAILED, {
            Timestamp: new Date().toISOString(),
            error: 'No Access'
        });
        return (
            <Stack gap={3} className="onboarding-content background-gray m-auto">
                <img src={NotAuthorizedImage} className="icon-image" alt="Not Authorized Icon" />
                <p className="status-text">
                    Sorry, you do not have access to this Ford Energy Management page.
                </p>
                <div className="contact-link">
                    Having trouble? <a href="mailto:myenergy@ford.com">Contact Us.</a>
                </div>
                <PrivacyLinks data-test-id="privacy-links" />
            </Stack>
        );
    } else if (onboardStatus === 'OPTED OUT') {
        trackEvent(AMPLITUDE_EVENTS.IP_OPTED_OUT);
        return (
            <Stack gap={3} className="onboarding-content background-gray m-auto">
                <img src={NotAuthorizedImage} className="icon-image" alt="Not Authorized Icon" />
                <p className="status-text">
                    It looks like you’ve turned off Ford Intelligent Power.
                </p>
                <p className="status-text">
                    Please turn this feature back on in your vehicle’s settings menu to continue
                    using Intelligent Power.
                </p>
                <div className="contact-link">
                    Having trouble? <a href="mailto:myenergy@ford.com">Contact Us.</a>
                </div>
                <PrivacyLinks data-test-id="privacy-links" />
            </Stack>
        );
    } else if (onboardStatus === 'DISCONNECTED') {
        trackEvent(AMPLITUDE_EVENTS.IP_DISCONNECTED);
        return (
            <Stack gap={3} className="onboarding-content background-gray m-auto">
                <img src={NotAuthorizedImage} className="icon-image" alt="Not Authorized Icon" />
                <p className="status-text">
                    To use Ford Intelligent Power, please update your connectivity settings using
                    your vehicle’s center touch screen.
                </p>
                <ol className="ordered-list -indent-[20px] px-12">
                    <li>
                        1. Open settings by clicking on the vehicle icon in the top left corner of
                        your vehicle’s screen
                    </li>
                    <li>
                        2. Select <b>Connectivity</b> from the settings menu
                    </li>
                    <li>
                        3. Open <b>Connected Vehicle Features</b>
                    </li>
                    <li>
                        4. Toggle on <b>Vehicle Connectivity, Share Vehicle Data</b>, and{' '}
                        <b>Share Vehicle Location</b>
                    </li>
                </ol>
                <div className="contact-link">
                    Having trouble? <a href="mailto:myenergy@ford.com">Contact Us.</a>
                </div>
                <PrivacyLinks data-test-id="privacy-links" />
            </Stack>
        );
    } else if (onboardStatus === 'PCT NOT SET') {
        trackEvent(AMPLITUDE_EVENTS.IP_PCT_NOT_SET);
        return (
            <Stack gap={3} className="onboarding-content background-gray m-auto">
                <img src={NotAuthorizedImage} className="icon-image" alt="Not Authorized Icon" />
                <p className="status-text">
                    To use Ford Intelligent Power, please set a Preferred Charge Time in the
                    FordPass app or in your vehicle.
                </p>
                <ol className="ordered-list -indent-[20px] px-12">
                    <li>
                        1. Open your <b>FordPass</b> app (linked to your EV) on your mobile phone
                    </li>
                    <li>
                        2. Select <b>Energy</b> from the bottom tabs
                    </li>
                    <li>
                        3. Select <b>Preferred Charge Times</b> under the header <b>Schedule</b>
                    </li>
                </ol>
                <div className="contact-link">
                    Having trouble? <a href="mailto:myenergy@ford.com">Contact Us.</a>
                </div>
                <PrivacyLinks data-test-id="privacy-links" />
            </Stack>
        );
    } else if (onboardStatus === 'FAILED') {
        trackEvent(AMPLITUDE_EVENTS.IP_ERROR);
        return (
            <Stack gap={3} className="onboarding-content bg-white" data-testid="onboarding-page">
                <img src={WarningIcon} className="icon-image" alt="Warning Icon" />
                <p className="status-text">Your onboarding was not successful</p>
                <p className="status-text">
                    Please <a href="mailto:myenergy@ford.com">contact us</a> to continue.
                </p>
                <Button className="login-button mx-auto mt-4" onClick={Logout}>
                    Sign Out
                </Button>
                <PrivacyLinks data-test-id="privacy-links" />
            </Stack>
        );
    } else {
        trackEvent(AMPLITUDE_EVENTS.IP_ERROR);
        return (
            <Stack gap={3} className="onboarding-content bg-white" data-testid="onboarding-page">
                <img src={WarningIcon} className="icon-image" alt="Warning Icon" />
                <p className="status-text">Sorry, we have encountered an unexpected error.</p>
                <p className="status-text">
                    Please <a href="mailto:myenergy@ford.com">contact us</a> to continue.
                </p>
                <Button className="login-button mx-auto mt-4" onClick={Logout}>
                    Sign Out
                </Button>
                <PrivacyLinks data-test-id="privacy-links" />
            </Stack>
        );
    }
}
